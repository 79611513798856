import React from "react"
import Slider from "react-slick"
import Container from "components/atoms/Container"
import Section from "components/atoms/Section"
import Image from "components/atoms/Image"

const WhatToMakeSection = ({ content }) => {
  const { dishes, title } = content

  const settingsSlideFor = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    infinite: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          dots: true,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "0",
        },
      },
    ],
  }

  return (
    <Section className="product-make-section bg-section inner-padding">
      <Container>
        <h2 className="title text-center">{title}</h2>
        <div className="row prod-card-row">
          <div className="col-sm-12 card-row">
            <Slider {...settingsSlideFor} className="productSlick">
              {dishes.map((dish, idx) => (
                <div className="prod-card" key={idx}>
                  <div className="card-img">
                    <Image image={dish.image.asset} className="fact-keto-img" isData />
                  </div>
                  <div className="card-details">
                    <a href="#" className="card-link">
                      {dish.dishName}
                    </a>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default WhatToMakeSection
