import React, { useEffect, useState } from "react"
import Container from "components/atoms/Container"
import Image from "components/atoms/Image"
import Section from "components/atoms/Section"
import arrow from "assets/images/arrow.png"

const JustGiveMeTheFacts = ({ content, flavors }) => {
  const { description, title, serving } = content
  const filteredFlavors = flavors.filter(flavor => flavor.isFlavor)

  const [selectedFaq, setSelectedFaq] = useState(0)
  const [selectedFlavor, setSelectedFlavor] = useState(0)
  const [nutrition, setNutrition] = useState(filteredFlavors[0]?._rawNutritionFacts)

  const {
    bgColor,
    calories,
    carbohydrates,
    dealsWith,
    fats,
    ingredients,
    protein,
    servingSize,
    nutritionImage,
    nutritionHeading,
    nutritionSuffix,
    breakdown,
  } = nutrition

  const handleSelectFlavor = idx => {
    setSelectedFlavor(idx)
    setNutrition(filteredFlavors[idx]?._rawNutritionFacts)
  }

  const [total, setTotal] = useState([])

  useEffect(() => {
    let data = []
    breakdown?.forEach(item => data.push(item.value))
    setTotal(data)
  }, [selectedFlavor])

  return (
    <Section
      className="just-give-me-the-facts-section section-wrapper inner-padding color-section bg-brown-section keto-gummy"
      bgColor={bgColor.hex}
      btmPadding
    >
      <Container>
        <h2 className="title">
          {title}
          <p className="tag-line">{description}</p>
        </h2>
        <div className="facts-section-inner">
          <div className="facts-section-col">
            <div className="facts-section">
              <div className="facts-flavor">
                {filteredFlavors.map((flavor, index) => (
                  <div
                    className={`facts-flv-item ${selectedFlavor === index && "active"}`}
                    onClick={() => handleSelectFlavor(index)}
                    key={index}
                  >
                    <div className={selectedFlavor === index ? "facts-flv-link active" : "facts-flv-link"}>
                      <span className="pfi-img">
                        <Image image={flavor.flavorIcon.asset} className="flav-img" />
                      </span>
                      <span className="pfi-text">{flavor.flavor}</span>
                    </div>
                    {(selectedFlavor === index - 1 ||
                      (index === 0 && selectedFlavor === filteredFlavors.length - 1 && selectedFlavor > 0)) && (
                      <>
                        <div className="facts-guide-arrow">
                          <img src={arrow} alt="" />
                        </div>
                        <div className="facts-guide-text">
                          Tap To See
                          <br />
                          Another Product
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="facts-details">
              <div className="facts-title">Nutrition Facts</div>
              <div className="facts-text">{`${serving} serving per container`}</div>
              <div className="fact-title-flex">
                <div className="fact-title-flex-inner">
                  <div className="facts-subtitle">Serving size</div>
                  <div className="facts-subtitle">{servingSize}</div>
                </div>
              </div>
              <div className="fact-title-flex calories">
                <div className="fact-title-flex-inner">
                  <div className="fact-title-flex-inner-left">
                    <div className="facts-text">Amount per serving</div>
                    <div className="facts-subtitle">Calories</div>
                  </div>
                  <div className="facts-subtitle font-40">{calories}</div>
                </div>
              </div>

              <div className="facts-calories-table-wrapper">
                <div className="facts-calories-table">
                  <div className="facts-calories-row">Total Fat</div>
                  <div className="facts-calories-row">{fats.totalFat}g</div>
                </div>
                <div className="facts-calories-table sub-table">
                  <div className="facts-calories-row">Saturated Fat</div>
                  <div className="facts-calories-row">{fats.saturatedFat}g</div>
                </div>
                <div className="facts-calories-table sub-table">
                  <div className="facts-calories-row">Trans Fat</div>
                  <div className="facts-calories-row">{fats.transFat}g</div>
                </div>
                <div className="facts-calories-table">
                  <div className="facts-calories-row">Total Carbohydrate</div>
                  <div className="facts-calories-row">{carbohydrates.totalCarbohydrate}g</div>
                </div>
                <div className="facts-calories-table sub-table">
                  <div className="facts-calories-row">Dietary Fiber</div>
                  <div className="facts-calories-row">{carbohydrates.dietaryFiber}g</div>
                </div>
                <div className="facts-calories-table sub-table">
                  <div className="facts-calories-row">Total Sugar</div>
                  <div className="facts-calories-row">{carbohydrates.totalSugar}g</div>
                </div>
                <div className="facts-calories-table inner-table">
                  <div className="facts-calories-row">Includes {carbohydrates.addedSugar}g Added Sugars</div>
                </div>
                <div className="facts-calories-table sub-table">
                  <div className="facts-calories-row">Sugar Alcohol</div>
                  <div className="facts-calories-row">{carbohydrates.sugarAlcohol}g</div>
                </div>
                <div className="facts-calories-table">
                  <div className="facts-calories-row">Protein</div>
                  <div className="facts-calories-row">{protein}g</div>
                </div>
              </div>
            </div>
            <div className="facts-details text-center">
              <div className="facts-details-top">{nutritionHeading}</div>
              <div className="facts-details-mid">
                {breakdown?.map((flavor, index) => {
                  return (
                    <div className="facts-details-inner" key={index}>
                      {index > 0 ? `-` : ``} {flavor?.value}g {flavor?.text}
                    </div>
                  )
                })}
              </div>
              <div className="facts-details-bottom">
                = {total.length > 0 && total.reduce((v, i) => v - i)}g Net Carbs Per {nutritionSuffix}
              </div>
            </div>
            <div className="facts-details">
              <div className="facts-details-table">
                <div className="facts-details-row">Carbs</div>
                <div className="facts-details-row graph-value">{carbohydrates.totalCarbohydrate}g</div>
                <div className="facts-details-row">
                  <div className="k-progress red">
                    <span className="k-pro" style={{ width: `${carbohydrates.totalCarbohydrate}%` }}></span>
                  </div>
                </div>
              </div>
              <div className="facts-details-table">
                <div className="facts-details-row">Protein</div>
                <div className="facts-details-row graph-value">{protein}g</div>
                <div className="facts-details-row">
                  <div className="k-progress yellow">
                    <span className="k-pro" style={{ width: `${protein}%` }}></span>
                  </div>
                </div>
              </div>
              <div className="facts-details-table">
                <div className="facts-details-row">Fat</div>
                <div className="facts-details-row graph-value">{fats.totalFat}g</div>
                <div className="facts-details-row">
                  <div className="k-progress green">
                    <span className="k-pro" style={{ width: `${fats.totalFat}%` }}></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="facts-section-col ingredients">
            <div className="facts-section desktop-only">
              <div className="facts-flavor">
                {filteredFlavors.map((flavor, index) => (
                  <div
                    className={`facts-flv-item ${selectedFlavor === index && "active"}`}
                    onClick={() => handleSelectFlavor(index)}
                    key={index}
                  >
                    <div className={selectedFlavor === index ? "facts-flv-link active" : "facts-flv-link"}>
                      <span className="pfi-img">
                        <Image image={flavor.flavorIcon.asset} className="flav-img" />
                      </span>
                      <span className="pfi-text">{flavor.flavor}</span>
                    </div>
                    {(selectedFlavor === index - 1 ||
                      (index === 0 && selectedFlavor === filteredFlavors.length - 1 && selectedFlavor > 0)) && (
                      <>
                        <div className="facts-guide-arrow">
                          <img src={arrow} alt="" />
                        </div>
                        <div className="facts-guide-text">
                          Tap To See
                          <br />
                          Another Product
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="facts-section">
              <div className="facts-title">Ingredients</div>
              <p className="facts-content">{ingredients}</p>
            </div>
            <div className="facts-section">
              <div className="facts-title">So What’s The Deal With…</div>
              <div className="accordion-wrapper">
                {dealsWith.map((deal, idx) => (
                  <div
                    className={selectedFaq === idx ? "accordion-item active" : "accordion-item"}
                    onClick={() => setSelectedFaq(idx)}
                    key={idx}
                  >
                    <div className="accordion-link">
                      {deal.title}
                      <span className="accordion-icon"></span>
                    </div>
                    <div className="accordion-answer">
                      <p className="accordion-answer-text">{deal.answer}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="facts-section-col">
            <div className="fact-keto-image">
              {nutritionImage && <Image image={nutritionImage?.asset} className="fact-keto-img" isData />}
            </div>
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default JustGiveMeTheFacts
