import React from "react"
import Container from "../../atoms/Container"
import Section from "../../atoms/Section"
import { Link } from "gatsby"

const RealReviewSection = ({ productId }) => {
  return (
    <Section className="real-reviews-section inner-padding bg-section">
      <Container>
        <h2 className="title text-center">Real Buyer Reviews</h2>
        <div
          id="stamped-main-widget"
          data-product-id={productId}
          data-name="##product.title##"
          data-url="##product.url##"
          data-image-url="##product.image##"
          data-description="##product.description##"
          data-product-sku="##product.handle##"
        ></div>
        <div className="row real-row">
          <div className="eaters-link">
            <Link to="/reviews/" className="link-hvr red">
              Read all reviews
            </Link>
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default RealReviewSection
