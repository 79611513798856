import React from "react"
import { useState } from "react"

import Container from "../../atoms/Container"
import Section from "../../atoms/Section"

import ImagePreviewer from "../../molecules/ImagePreviewer"
import ProductInfoWrapper from "../../molecules/ProductInfoWrapper"

const ProductDetails = ({ product, shopifyVariants, activeFlavor }) => {
  const [activeFlavorImageKey, setActiveFlavorImageKey] = useState("")
  const [curQty, setCurQty] = useState(1)
  return (
    <Section className="product-details-sections">
      <Container>
        <div className="product-details-wrapper">
          <div className="product-details-cols image-slider">
            <ImagePreviewer
              product={product}
              activeFlavorImageKey={activeFlavorImageKey}
              qty={curQty}
              activeFlavor={activeFlavor}
            />
          </div>
          <div className="product-details-cols content-wrap">
            {shopifyVariants ? (
              <ProductInfoWrapper
                product={product}
                activeFlavor={activeFlavor}
                shopifyVariants={shopifyVariants}
                setActiveFlavorImageKey={setActiveFlavorImageKey}
                setCurQty={setCurQty}
              />
            ) : (
              <h2>This product is not ready to sell!</h2>
            )}
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default ProductDetails
