import React, { useState } from "react"

import adc1 from "../../../assets/images/adc1.png"
import adc2 from "../../../assets/images/adc2.png"
import adc3 from "../../../assets/images/adc3.png"
import adc4 from "../../../assets/images/adc4.png"
import Stepper from "../../atoms/Stepper"

const discountData = [
  { text: "3% off", discount: 3 },
  { text: "6% off", discount: 6 },
  { text: "9% off", discount: 9 },
  { text: "12% off", discount: 12 },
  { text: "15% off", discount: 15 },
]

const BuildingBundleProduct = ({ product: { id, image, productName, qty }, setQty }) => {
  return (
    <div className="prod-item-row">
      <div className="col-left">
        <div className="prod-img">
          <img src={image} alt={productName} />
        </div>
      </div>
      <div className="col-right">
        <div className="prod-title-wrapper">
          <div className="prod-title">{productName}</div>
          <button className="close-btn" id="removeItem">
            <i className="h-icon-cancel"></i>
          </button>
        </div>
        <div className="input-wrapper">
          <div className="input-group input-qty">
            <span className="input-group-btn">
              <button
                type="button"
                className="quantity-left-minus btn  btn-number"
                data-type="minus"
                data-field=""
                onClick={() => qty > 0 && setQty({ id, qty: qty - 1 })}
              >
                <span>&#8722;</span>
              </button>
            </span>
            <div className="product-qty">{qty}</div>
            <span className="input-group-btn">
              <button
                type="button"
                className="quantity-right-plus btn btn-number"
                data-type="plus"
                data-field=""
                onClick={() => setQty({ id, qty: qty + 1 })}
              >
                <span>&#43;</span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

const BuildingBundler = () => {
  const [buildingBundleProducts, setBuldingBundlProducts] = useState([
    {
      id: 1,
      image: adc1,
      productName: "Seeded Wheat",
      qty: 0,
      productValue: 11.99,
    },
    {
      id: 2,
      image: adc2,
      productName: "Golden Wheat",
      qty: 0,
      productValue: 11.99,
    },
    {
      id: 3,
      image: adc3,
      productName: "Dark Wheat",
      qty: 0,
      productValue: 11.99,
    },
    {
      id: 4,
      image: adc4,
      productName: "Cinnamon Wheat",
      qty: 0,
      productValue: 11.99,
    },
  ])

  const setQty = ({ id, qty }) => {
    setBuldingBundlProducts(
      buildingBundleProducts.map(prod => {
        if (prod.id === id) {
          return { ...prod, qty }
        } else return prod
      })
    )
  }

  const totalQty = buildingBundleProducts.reduce((total, cur) => total + cur.qty, 0)
  let discountIndex = totalQty <= 6 ? (totalQty < 2 ? 0 : totalQty - 1) : 5

  return (
    <>
      <div className="building-bundle-panel-header">
        <h3 className="building-bundle-title">Build Your Own Variety Pack</h3>
        {totalQty === 0 || totalQty === 1 ? (
          <p className="tag-text">
            Add one more to unlock a<span className="text-green">{" 3%"}</span> discount!
          </p>
        ) : (
          <p className="tag-text">
            Congrats, you’ve unlocked a{" "}
            <span className="text-green">{discountIndex !== -1 ? discountData[discountIndex - 1].discount : "0"}%</span>{" "}
            discount!
          </p>
        )}
        <Stepper steps={discountData} activeIndex={discountIndex} />
      </div>
      <div className="building-bundle-panel-body">
        <div className="building-bundle-prods-wraper">
          {buildingBundleProducts.map((product, index) => (
            <BuildingBundleProduct product={product} setQty={setQty} key={index} />
          ))}
        </div>
      </div>
      <div className="building-bundle-panel-footer">
        <div className="total-row">
          <div className="col-left">
            <h4 className="total-title">Bundle price:</h4>
          </div>
          <div className="col-right">
            <div className="price-wrap">
              <span className="total-price">$47.96</span>
              <span className="total-price-new">$39.99</span>
            </div>
          </div>
        </div>
        <div className="btn-sec">
          <button className="btn btn-primary">Add To Cart</button>
        </div>
        <div className="note-list">
          <p className="note-item">30 Day Money Back Guarantee!</p>
          <p className="note-item">Orders Dispatched Daily at 2pm PST</p>
        </div>
      </div>
    </>
  )
}

const BuildingBundleModal = ({ open, onClose }) => {
  return (
    <div className={open ? "building-bundle-side-drawer-wrapper active" : "building-bundle-side-drawer-wrapper"}>
      <div className={open ? "backdrop" : ""} onClick={onClose}></div>
      <aside className={open ? "building-bundle-sidebar active" : "building-bundle-sidebar"} id="bundalSidebar">
        <button className="close-btn" onClick={onClose}>
          <i className="h-icon-cancel"></i>
        </button>
        <div className="building-bundle-panel">
          <BuildingBundler />
        </div>
      </aside>
    </div>
  )
}

export default BuildingBundleModal
