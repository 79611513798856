import React from "react"
import { value } from "lodash/seq"

const Stepper = ({ isInitialStep, steps, activeIndex }) => {
  return (
    <div className={isInitialStep ? "stepper" : "stepper"}>
      {steps.map((step, index) => {
        return (
          <div className="stepper-inner" key={index}>
            <div className={index + 1 == activeIndex ? "stepper-step active" : "stepper-step"}>
              <span>{step.text} </span>
              <span className="check">
                <i className={`icon-check ${step.value ? "" : "invisible"}`} />
              </span>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Stepper
