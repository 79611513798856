import React from "react"

const serializers = {
  type: {},
  marks: {
    // centered text content
    textCenter: ({ children }) => {
      return <div style={{ textAlign: "center" }}>{children}</div>
    },
  },
}

export default serializers
