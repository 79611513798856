import React from "react"
import Image from "components/atoms/Image"

import Container from "components/atoms/Container"
import Section from "components/atoms/Section"

const BestThingAboutProduct = ({ content }) => {
  const { heading, image, productContents } = content

  return (
    <Section className="best-product-sec">
      <Container>
        <h2 className="title text-center">{heading}</h2>
        <div className="row best-prd-row">
          <div className="col-sm-3 col-left">
            <div className="brea-fetures-wrapper">
              <div
                className="brea-fetures"
                style={{ textAlign: productContents.textAlign1 ? "right" : productContents.textCenter1 && "center" }}
              >
                <h3 className="subtitle">{productContents.title1}</h3>
                <p className="description">{productContents?.description1}</p>
              </div>
              <div
                className="brea-fetures"
                style={{ textAlign: productContents.textAlign2 ? "right" : productContents.textCenter2 && "center" }}
              >
                <h3 className="subtitle">{productContents.title2}</h3>
                <p className="description">{productContents?.description2}</p>
              </div>
              <div
                className="brea-fetures"
                style={{ textAlign: productContents.textAlign3 ? "right" : productContents.textCenter3 && "center" }}
              >
                <h3 className="subtitle">{productContents.title3}</h3>
                <p className="description">{productContents?.description3}</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-middle">
            <Image image={image.asset} className="product-main-img" isData />
          </div>
          <div className="col-sm-3 col-right">
            <div className="brea-fetures-wrapper">
              <div
                className="brea-fetures"
                style={{ textAlign: productContents.textAlign4 ? "right" : productContents.textCenter4 && "center" }}
              >
                <h3 className="subtitle">{productContents.title4}</h3>
                <p className="description">{productContents?.description4}</p>
              </div>
              <div
                className="brea-fetures"
                style={{ textAlign: productContents.textAlign5 ? "right" : productContents.textCenter5 && "center" }}
              >
                <h3 className="subtitle">{productContents.title5}</h3>
                <p className="description">{productContents?.description5}</p>
              </div>
              <div
                className="brea-fetures"
                style={{ textAlign: productContents.textAlign6 ? "right" : productContents.textCenter6 && "center" }}
              >
                <h3 className="subtitle">{productContents.title6}</h3>
                <p className="description">{productContents?.description6}</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default BestThingAboutProduct
