import React from "react"
import Image from "components/atoms/Image"
import Container from "components/atoms/Container"
import Section from "components/atoms/Section"
import TapToPlaySoundVideo from "components/atoms/TapToPlaySoundVideo"
import { Link } from "gatsby"

const CustomerReviews = ({ content }) => {
  const { reviews, title, videoUrl } = content

  return (
    <Section className="section-wrapper eaters-section bg-section">
      <Container>
        <div className="eaters-wrapper">
          <div className="row eaters-row">
            <div className="col-sm-6 eaters-cols">
              <h2 className="title text-left">{title}</h2>
              <div className="eaters-inner">
                {reviews.map((review, idx) => (
                  <div className="eaters-item" key={idx}>
                    <div className="eaters-top">
                      <div className="eaters-avtar">
                        <div className="avtar">
                          <Image image={review.authorImage.asset} className="avtimg" isData />
                          <i className="icon-check fill"></i>
                        </div>
                      </div>
                      <div className="eaters-profile">
                        <div className="profile-title">{review.author}</div>
                        <div className="profile-location">{review.location}</div>
                        <div className="profile-rating">
                          <div className="rating">
                            <span className="icon-star fill xs2"></span> <span className="icon-star fill xs2"></span>
                            <span className="icon-star fill xs2"></span> <span className="icon-star fill xs2"></span>
                            <span className="icon-star fill xs2"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="eaters-bottom">
                      <div className="eaters-title">{review.title}</div>
                      <div className="eaters-content">{review.description}</div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="eaters-link">
                <Link to="/reviews/" className="link-hvr red">
                  Read all reviews
                </Link>
              </div>
            </div>
            <div className="col-sm-6 eaters-cols">
              <div className="eaters-video">
                <TapToPlaySoundVideo url={`https://player.vimeo.com/video/${videoUrl}`} width="100%" height="100%" />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default CustomerReviews
