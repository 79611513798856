import React, { useState, useEffect, useRef } from "react"
import Image from "components/atoms/Image"
import SubQtyBadge from "components/atoms/SubQtyBadge"
import Slider from "react-slick"

import ArrowLeft from "assets/images/arrow-left.svg"
import ArrowRight from "assets/images/arrow-right.svg"
import ImageOverlay from "../ImageOverlay/ImageOverlay"

const SliderPrevArrow = props => {
  const { className, style, onClick } = props
  return <img src={ArrowLeft} className={className} style={{ ...style, left: "10px", zIndex: 1 }} onClick={onClick} />
}

const SliderNextArrow = props => {
  const { className, style, onClick } = props
  return <img src={ArrowRight} className={className} style={{ ...style, right: "10px", zIndex: 1 }} onClick={onClick} />
}

const ImagePreviewer = ({ product, activeFlavorImageKey, qty, activeFlavor }) => {
  const { contains, name } = product

  const sliderRef = useRef()
  const [allImages, setAllImages] = useState([])
  const [activeImageIndex, setActiveImageIndex] = useState(0)
  const [selFlavor, setSelFlavor] = useState()

  const [isMobile, setIsMobile] = useState(false)
  const [openOverlay, setOpenOverlay] = useState(false)
  const [clickedId, setClickedId] = useState(null)

  useEffect(() => {
    const tempImgs = product.flavors.reduce((allImages, variant) => {
      variant.images.map((image, idx) => (image.showBadge = idx === 0))
      return [...allImages, ...variant.images]
    }, [])

    setSelFlavor(product.flavors[activeFlavor])
    setIsMobile(window.innerWidth <= 850)
    setAllImages(tempImgs)
  }, [product.flavors])

  useEffect(() => {
    if (activeFlavorImageKey) {
      const index = allImages.findIndex(image => image._key === activeFlavorImageKey)
      handleImageChange(index)
    }
  }, [activeFlavorImageKey])

  const settingsSlideFor = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    autoplay: false,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    swipe: false,
    beforeChange: (current, next) => setActiveImageIndex(next),
    responsive: [
      {
        breakpoint: 850,
        settings: {
          // centerMode: true,
          // centerPadding: "0",
          // fade: false,
          // slidesToShow: 1,
          // slidesToScroll: 1,
          // infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          fade: true,
          autoplay: false,
        },
      },
    ],
  }

  const handleImageChange = index => {
    sliderRef.current.slickGoTo(index)
    setActiveImageIndex(index)
  }

  const handleImageOverlayOpen = e => {
    setOpenOverlay(true)
    setClickedId(e.target.dataset.id)
  }

  const handleImageOverlayClose = () => {
    setOpenOverlay(false)
  }

  return (
    <>
      <div className="product-details-top-mobile">
        <div className="product-details-wrapper">
          <h2 className="title text-left">{name}</h2>
          <div className="ingredients">
            {contains?.length > 0 &&
              contains.map((contain, idx) => {
                return (
                  <div className="ingredients-inner" key={idx}>
                    {contain.name}
                  </div>
                )
              })}
          </div>
        </div>
        <div className="rating-wrapper">
          <span className="stamped-product-reviews-badge stamped-main-badge" data-id={product?.shopifyProductId}></span>
        </div>
      </div>

      <div className="product-slider">
        <div className="small-images-list">
          {allImages.map((image, idx) => (
            <div
              className={`small-image-wrapper ${activeImageIndex === idx ? "small-image-wrapper-selected" : ""}`}
              key={idx}
              onClick={() => handleImageChange(idx)}
            >
              <Image image={image.asset} className="product-main-img" />
            </div>
          ))}
        </div>

        <div className="slider-for">
          <Slider {...settingsSlideFor} ref={sliderRef} className="slider-container">
            {allImages.map((image, idx) => (
              <div className="slider-main-inner" key={image._key}>
                <div className="product-main-image d-flex">
                  {isMobile && activeImageIndex == idx && (
                    <img
                      onClick={e => handleImageOverlayOpen(e)}
                      data-id={image._key}
                      src={image.asset.gatsbyImageData.images.fallback.src}
                      alt={image._key}
                    />
                  )}
                  {!isMobile && <Image image={image.asset} className="product-main-img" />}
                  {image.showBadge && <SubQtyBadge qty={qty} selFlavor={selFlavor} />}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <ImageOverlay
        isOpen={openOverlay}
        images={allImages}
        clickedId={clickedId}
        onClose={() => handleImageOverlayClose()}
      />
    </>
  )
}
export default ImagePreviewer
