import React, { useEffect, useState, useRef } from "react"
import ArrowLeft from "assets/images/arrow-left.svg"

const ImageOverlay = ({ images, clickedId, isOpen = false, onClose }) => {
  const closeRef = useRef(null)

  const [overlayPadding, setOverlayPadding] = useState(0)

  useEffect(() => {
    if (clickedId) {
      const overlay = document.querySelector(".image-overlay")
      // find div with id so we can scroll to element in overlay
      const selectedImg = document.querySelector(`div[id="${clickedId}"]`)
      // scroll to element with offset of fixed header
      overlay.scrollTo(0, selectedImg.offsetTop - overlayPadding)
    }
  }),
    [clickedId]

  useEffect(() => {
    //Scroll to top of window
    document.querySelector(".image-overlay").scrollTo(0, 0)

    if (isOpen) {
      document.body.classList.add("disable-scroll")
    } else {
      document.body.classList.remove("disable-scroll")
    }
  }, [isOpen])

  useEffect(() => {
    setOverlayPadding(closeRef.current.clientHeight)
  }, [isOpen])

  const handleClose = e => {
    e.preventDefault()
    onClose && onClose()
  }

  return (
    <div className={`image-overlay ${isOpen ? "open" : ""}`} style={{ paddingTop: `${overlayPadding}px` }}>
      <div className="image-overlay-close" ref={closeRef}>
        <a href="#" onClick={e => handleClose(e)}>
          <img src={ArrowLeft} alt="Arrow Left" aria-hidden="true" /> Back to product
        </a>
      </div>
      <div className="overlay-images">
        {images.map(image => (
          <div key={image.key} id={image._key}>
            <img src={image.asset.gatsbyImageData.images.fallback.src} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default ImageOverlay
