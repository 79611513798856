import React from "react"

import Container from "../../atoms/Container"
import Section from "../../atoms/Section"

const AskUsAnythingForMobile = () => (
  <Section className="mobile-contact-section section-wrapper">
    <Container>
      <div className="contact-wrapper">
        <h2 className="title text-left">Ask Us Anything</h2>
        <p className="contact-title">
          You have questions? We have answers. <br />
          Reach us Monday - Friday 9am to 6pm (EST)
        </p>
        <div className="contact-faqs">
          <div className="contact">
            <i className="icon-phone"></i> Text KMKHELP to{" "}
            <a href="tel:+1833-745-0976" className="link">
              833-745-0976
            </a>
          </div>
          <div className="contact">
            <i className="icon-help"></i> Help Center
          </div>
          <div className="contact">
            <i className="icon-chat"></i> Start Chat
          </div>
        </div>
      </div>
    </Container>
  </Section>
)

export default AskUsAnythingForMobile
