import React from "react"
import { Check } from "@styled-icons/fa-solid"
const WhySubscribeModal = ({ onClose }) => {
  return (
    <div className="why-subscribe-modal-wrapper active">
      <div className="why-subscribe-popup-wrapper">
        <div className="why-subscribe-popup">
          <div className="backdrop" onClick={onClose} />
          <div className="popup-content">
            <div className="close" onClick={onClose}>
              <i className="icon-close" aria-hidden="true" />
            </div>
            <div className="popup-inner">
              <div className="facts-details">
                <p className="title">Membership Savings</p>
                <div className="checklist-flex">
                  <div>
                    <Check color="#2ba86d" size={12} />
                  </div>
                  <div className="checklist-title">
                    <div>
                      <span className="green-span">10%</span> off subscription products
                    </div>
                  </div>
                </div>
                <div className="checklist-flex">
                  <div>
                    <Check color="#2ba86d" size={12} />
                  </div>
                  <div className="checklist-title">
                    <div>Buy More, Save More</div>
                    <p>Save up to 25% off with our quality discount tiers</p>
                  </div>
                </div>
                <div className="checklist-flex">
                  <div>
                    <Check color="#2ba86d" size={12} />
                  </div>
                  <div className="checklist-title">
                    <div>Cancel Anytime</div>
                    <p>Pause, skip and cancel anytime.</p>
                  </div>
                </div>
                <div className="checklist-flex">
                  <div>
                    <Check color="#2ba86d" size={12} />
                  </div>
                  <div className="checklist-title">
                    <div>Control Your Delivery Schedule</div>
                    <p>Get a delivery every 1, 2, 3, 4, 5 or 6 weeks</p>
                  </div>
                </div>
                <a className="link red" href="https://shop.kissmyketo.com/pages/subscription-faq" target="_blank">
                  Learn more
                </a>
                <button className="btn btn-primary btn-block" onClick={onClose}>
                  Got It!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhySubscribeModal
