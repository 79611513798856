import pluralize from "pluralize"
import React from "react"

const QuantityLabel = ({ itemQty, totalQty, unitText }) => {
  const totalItems = itemQty * totalQty
  const pluralisedText = totalItems > 1 ? pluralize.plural(unitText) : unitText

  return (
    <p className="subtitle">
      Quantity ({totalItems} {pluralisedText})
    </p>
  )
}
export default QuantityLabel
