import React from "react"

import Container from "components/atoms/Container"
import Section from "components/atoms/Section"

import StackUpTable from "components/molecules/StackUpTable"

const ProductHowDoWeStackUp = ({ content }) => {
  const { extraNote, stacks, title, headers } = content
  return (
    <Section className={" inner-padding bg-section"}>
      <Container>
        <h2 className="title text-center">{title}</h2>
        <StackUpTable notes={extraNote} stacks={stacks} headers={headers} />
      </Container>
    </Section>
  )
}

export default ProductHowDoWeStackUp
